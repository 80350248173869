import { Grid, Skeleton, ThemeProvider } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { sliderStyle, useStyles } from './HomeSkeleton.styles';

export function HomeSkeleton() {
    const { classes } = useStyles();

    return (
        <Grid className={classes.homePageContainer}>
            <Grid container alignContent="flex-start" className={classes.homeSubContainer}>
                <ThemeProvider theme={sliderStyle}>
                    <Skeleton variant="rectangular" animation="wave" />
                </ThemeProvider>
                <Grid container direction="row" className={classes.homeTileContainer}>
                    {Array(4)
                        .fill(true)
                        .map(() => (
                            <Grid key={uuidv4()} className={classes.homeTile}>
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    className={classes.homeTilePending}
                                />
                            </Grid>
                        ))}
                </Grid>
            </Grid>
        </Grid>
    );
}
